
import { defineComponent } from "vue";
import variables from "@/router/api";

export default defineComponent({
  name: "owner-building-policy-period-breakdown-component",
  components: {},
  props: {
    displayBreakdown: Boolean,
    building: Object,
    newBuilding: Object,
    summary: Object,
    newSummary: Object,
    initialTotalPremium: Number
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    }
  }
});
