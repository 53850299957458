
import { defineComponent } from "vue";
import variables from "@/router/api";

export default defineComponent({
  name: "motor-policy-period-breakdown-component",
  components: {},
  props: {
    displayBreakdown: Boolean,
    summary: Object,
    newSummary: Object,
    initialTotalPremium: Number,
    vehicles: Array,
    newVehicles: Array
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    }
  }
});
